const useStyles = () => {
	const styles = {
		1: `w-[50%] h-[40px] text-zinc-100 text-shadow-3
			isDesktop:px-12 border-[1px] border-cyan-500
		bg-cyan-600 rounded-md isMobile:w-[90%]
		box-shadow-2
		`,
	};
	return styles;
};

export default useStyles;
