export const GEMSTONE_FAQ = [
	// El primer objeto vacío está para 'solucionar' el bug del primer
	// contenedor que no se expande lo suficiente...
	{
		question: '',
		answer: [''],
	},
	{
		question: '¿Para qué sirve la Gemoterapia?',
		answer: [
			'Fundamentalmente la Gemoterapia sirve para estabilizar, alinear, armonizar o sanar el cuerpo Emocional, o Chakras.',
			'Muchas veces, y con el paso del tiempo, un desequilibrio energético, o conflicto Emocional, se refleja en el cuerpo en forma de algún programa de Adaptación Biológica, que es lo que comunmente se refiere como Enfermedad.',
			'En estos casos, al trabajar sobre el Cuerpo Energético y volverlo a armonizar, puede resultar en la solución de este programa de Adaptación Biológico (enfermedad), y sanar el cuerpo físico. Siendo este problema, un reflejo Físico del la causa original en el cuerpo Energético o Emocional.',
		],
	},
	{
		question: '¿Funciona a distancia?',
		answer: [
			'¡Sí! Los aspectos energéticos a distancia son los mismos que de manera presencial, ya que se trabaja sobre los cuerpos sutiles.',
			'Recuerda que al trabajar con los cuerpos energéticos, los conceptos de Tiempo y Espacio funcionan de una manera mucho más sutil.',
			'Por eso es que es posible incidir sobre el cuerpo energético de otro, conectándose a través del Eter sin importar la distancia.',
		],
	},
	{
		question: '¿Tengo que saber o hacer algo especial?',
		answer: [
			'No es necesario saber ni hacer nada especial durante la sesión. Tanto de manera presencial o a distancia.',
			'Si se hace a distancia, simplemente se puede uno disponer de manera tranquila en un estado de relajación, meditación, y abrirse al efecto y el poder sanador de los cristales.',
		],
	},
	{
		question: '¿Se puede encargar una sesión para un tercero?',
		answer: [
			'Sí, se puede, pero siempre es una condición imprescindible que quien vaya a recibir el trabajo de la sesión, dé su permiso conscientemente para que el Terapista pueda interactuar energéticamente con los cristales.',
			'No puede hacerse un tratamiento de sanación sin el consentimiento de quién lo va a recibir, ya que de hacerlo, simplemente el efecto no sería útil, o en ciertos casos podría generar un conflicto mayor, y resultar desfavorable tanto para el individuo como para el Terapista.',
		],
	},
	{
		question: '¿Se pueden hacer sesiones para bebes o niños?',
		answer: [
			'Sí. En caso de que el niño sea muy pequeño, es el padre, madre o responsable del niño quién brinda el consentimiento al Terapeuta para que pueda interactuar energéticamente con los cristales.',
			'Siempre en casos de terceros y similares, el Terapista hará una conexión en meditación con el individuo a tratar, para pedir el permiso directamente al Yo Superior, Espíritu, o Alma, correspondientemente.',
		],
	},
	{
		question:
			'¿Se puede hacer una sesión a alguien inconsciente (en estado de coma), alguien en estado Senil, con Alzheimer, o similar?',
		answer: [
			'Por norma general Sí, y en este caso, el que daría el consentimiento en el plano Físico sería el responsable moral de el individuo a tratar.',
			'Siempre en casos de terceros y similares, el Terapista hará una conexión en meditación con el individuo a tratar, para pedir el permiso directamente al Yo Superior, Espíritu, o Alma, correspondientemente.',
			'En estos casos lo correspondiente es contactar con nosotros, y ver los pormenores del caso en concreto, y encontrar la mejor manera de ayudar con los cristales.',
		],
	},
	{
		question: '¿Cuales son los efectos de la Gemoterapia?',
		answer: [
			'Los efectos pueden ser variados, dependiendo de qué esté transitando el individuo a quién se le practica.',
			'Por lo general se pueden encontrar efectos como: Recobrar la vitalidad, mejor sueño, mejor humor, liberación o ligereza emocional, recobrar la voluntad, claridad mental, positividad, quitar dolor de cabeza, creatividad, reconectar con uno mismo, etc.',
			'Siendo que la mayoría de estos efectos, son causados por un desequilibrio energético, la Gemoterapia sencillamente ayuda a recobrar ese equilibrio, el resto son "efectos secundarios".',
		],
	},
	{
		question: '¿Duele la Gemoterapia?',
		answer: [
			'¡No! El efecto de bienestar, en la mayoría de los casos es inmediato.',
			'En casos donde los conflictos de los cuerpos energéticos se desencadenaron en síntomas físicos, puede ocurrir que, al devolver la armonía al cuerpo energético, el cuerpo físico se disponga a sanar, o volver a su estado de funcionamiento normal.',
			'A veces, este proceso de "Reparación Biológica", puede estar acompañado de molestias, dependiendo el Programa Biológico que se esté trancitando.',
			'Por ejemplo, en algunos casos donde se lleva tiempo con el cuerpo energético en conflicto o disonancia, el cuerpo Biológico se Adapta para Sobrevivir. Al disolver el conflicto energético/emocional, el cuerpo Biológico se dispone a sanar, a veces acompañado por Fiebre, Vómitos, u otros síntomas de "Limpieza".',
		],
	},
	{
		question: '¿Cuántas sesiones necesito?',
		answer: [
			'Eso depende totalmente de vos.',
			'Con la Gemoterapia se ayuda a restablecer la armonía al cuerpo energético. Si esta desarmonía fue por una causa específica, probablemente con una sola encuentres tu centro nuevamente.',
			'Hay que tener en cuenta que si se lleva un estilo de vida donde uno interactua con "Malas Energías" frecuentemente; Como en el trabajo, en contexto doméstico, conflictos sociales en general, o pensamientos nocivos, puede ser que, por más sesiones de cualquier cosa que uno haga, vuelva el cuerpo energético a desarmonizarce.',
			'Hay quienes podrán disfrutar de las sanaciones con cristales periódicamente, o quizá varias sesiones para acompañar un proceso de sanación de una larga dolencia.',
			'Pero una sesión de Gemoterapia no es un "Escudo", para evadir malos hábitos, interferencias externas, pensamientos nocivos, agresiones energéticas, etc.',
		],
	},
	{
		question: '¿Puedo tomar una sesión si me siento bien?',
		answer: [
			'¡Claro! No es necesario padecer dolencias para tomar una sesión de Gemoterápia.',
			'Si tenés por venir una situaicón o evento en tu vida que sea importante, podés recibir el poder de los cristales para vivir eso con todo tu poder armonizado.',
			'Si tenés un exámen, una importante entrevista, un evento social, o cualquier cosa, se puede tomar antes o después, según desees, para cuidar el cuerpo energético',
			'También puedes tomarlo "cosméticamente", en lugar de un día de Spá, ¡un día de baño de cristales, para revigorizar el cuerpo energético!',
		],
	},
	{
		question: '¿Cómo es una sesión presencial?',
		answer: [
			'Las sesiones presenciales, son en la ciudad de Mar Del Plata, Argentina.',
			'Se reserva el turno previamente, donde se acuerda día y horario.',
			'La sesión dura aproximadamente una hora.',
			'Primero se conversa con el consultante sobre cual es el motivo por el que quiere la sesión de Gemoterapia, el Por qué, el Para qué, y las expectativas.',
			'Luego se recuesta en la camilla, con la compañía de una música relajante, y se colocan los cristales sobre los Chakras principales. (No es necesario quitarse la ropa para la práctica de la Gemoterapia)',
			'Con la ayuda de un Péndulo de Cristal (Radiestesia), se observan los Chakras y dónde se hallan el o los bloqueos.',
			'Luego con diferentes técnicas energéticas y el poder de los cristales, se ayuda a fluir, remover, desbloquear, sanar, disolver, o lo que cada situación requiera, el bloqueo o disonancia que pueda existir.',
			'Después de atender los bloqueos específicos, se realiza la alineación y activación de los Chakras.',
			'Al finalizar el tratamiento energético, se conversa sobre la experiencia, qué sintió el consultante y cosas que se puedan haber percibido durante la sesión que podrían ser relevantes para el proceso por el que se esté transitando.',
		],
	},
	{
		question: '¿Cómo es una sesión a distancia?',
		answer: [
			'Se reserva el turno previamente, donde se acuerda día y horario.',
			'La sesión dura aproximadamente una hora.',
			'Primero se realiza una pequeña video-llamada, para conocernos, y contactar con la energía del consultante.',
			'Se conversa con el consultante sobre cuál es el motivo por el que quiere la sesión de Gemoterapia, el Por qué, el Para qué, y las expectativas.',
			'Luego se comienza el tratamiento, para esto, se invita al consultante a estar en un momento y espacio en que pueda estar de manera tranquila y relajada, y si prefiere hacer una meditación y recostarse, es ideal.',
			'Sobre un paño, se colocan siete cristales, uno para cada Chakra, y se los vincula energéticamente con el consultante',
			'Con la ayuda de un Péndulo de Cristal (Radiestesia), se observan los Chakras (a través de los cristales previamente vinculados) y dónde se hallan el o los bloqueos energéticos del consultante.',
			'Luego con diferentes técnicas energéticas y el poder de los cristales, se ayuda a fluir, remover, desbloquear, sanar, disolver, o lo que cada situación requiera, el bloqueo o disonancia que pueda existir.',
			'Después de atender los bloqueos específicos, se realiza la alineación y activación de los Chakras.',
			'Al finalizar el tratamiento energético, se puede hacer otra pequeña video-llamada para conversar sobre la experiencia, qué sintió el consultante y cosas que se puedan haber percibido durante la sesión que podrían ser relevantes para el proceso por el que se esté transitando.',
			'A veces, como luego de la sesión se permanece en un agradable estado de relajación, se prefiere no realizar inmediatamente esa video-llamada, se le hace en forma de mensajes de audio una devolución al consultante de cosas que se puedan haber percibido durante la sesión, que puedan resultarle relevante para el proceso por el que esté transitando.',
		],
	},
];

// ¿Cómo es una sesión a distancia?

// Se pacta día y horario en el que realizan una pequeña video llamada, para conocer y conectar con la energía del consultante, charlamos sobre que le anda pasando y que es lo que espera de la sesión.
// Luego, sobre un paño, procedemos a colocar los cristales, en forma vertical, enviando energía a los chakras principales de la persona.
// Con la ayuda de un péndulo vemos en donde se encuentra el bloqueo, y con la técnica de Gemoterapia, procedemos a desbloquear y armonizar los vórtices de energía del consultante.
