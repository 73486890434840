import React from 'react';

const IconBurger = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 20 20'
			width='25'
			height='25'
			fill='black'
		>
			<path
				fillRule='evenodd'
				d='M2 5h16a1 1 0 0 0 0-2H2a1 1 0 1 0 0 2zm0 6h16a1 1 0 0 0 0-2H2a1 1 0 0 0 0 2zm0 6h16a1 1 0 0 0 0-2H2a1 1 0 0 0 0 2z'
				clipRule='evenodd'
			/>
		</svg>
	);
};

export default IconBurger;
