export const TAROT_TEXTS = {
	1: `El <b>Tarot Holístico</b> es práctica de la <b>Cartomancia</b> de manera integral, para encontrar respuestas y orientación en diferentes áreas de la vida.
   </br></br>

   <b>Tarot Espiritual</b>
   </br>
   Para acercar al individuo a contestar las grandes preguntas; ¿Quién soy, dónde estoy, por qué estoy acá, cuál es mi propósito, qué quiero, por qué me pasa esto, de dónde vengo, a dónde voy?
   </br>
   Contactamos con nuestro <b>Yo Superior</b>, para acceder a esta información que ya existe, y que está ahí, esperándonos.

   </br></br>

   <b>Tarot Terapéutico</b>
   </br>
   Para encontrar la raíz de nuestros males, dolencias, bloqueos y dificultades, <b>hacer consciente lo inconsciente</b>, y comenzar el camino de la sanación.
   </br>
   <b>Tú tienes el poder de sanarte a ti mismo.</b>
   </br>
   Solo falta hacer consciente las sombras, y crear un mapa del camino.

   </br></br>

   <b>Tarot Psicológico</b>
   </br>
   Para comprender con precisión tu sistema mental, para comprenderte a ti mismo, para comprender a aquellos que te rodean y son importantes en tu vida.
   </br>
   Para comprender cuáles son tus virtudes y tus debilidades, para comprender el vínculo entre <b>tu experiencia mental y emocional</b>.

   </br></br>

   <b>Tarot Evolutivo</b>
   </br>
   Para continuar el camino, para encontrar certidumbre en el siguiente paso, para desarrollar una mejor y más eficiente capacidad de <b>tomar decisiones</b>.
   </br>
   Contactaremos con la sabiduría del cosmos, para orientarnos hacia el mejor camino, en nuestra experiencia humana.
   `,
};
