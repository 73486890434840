const useStyles = () => {
	const styles = {
		element_container: `w-full h-[50px] flex justify-between items-center pl-5 my-1 bg-gradient-to-r from-slate-800/[70%]`,

		center_text: `	text-amber-300
											text-shadow-3 p-2`,

		arrow: 'rotate-180 scale-[60%] mr-5',
	};

	return styles;
};

export default useStyles;
