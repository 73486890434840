// {
//   sentence: '""',
//   author: ['']
// },

export const openingSentence = [
	{
		sentence: '"Lo esencial es invisible a los ojos."',
		author: ['Antoine de Saint-Exupéry', '"El Principito"'],
	},
	{
		sentence:
			'"Eres el dueño de tu vida y tus emociones, nunca lo olvides. Para bien y para mal."',
		author: ['Antoine de Saint-Exupéry', '"El Principito"'],
	},
	{
		sentence:
			'"Las personas mayores nunca pueden comprender algo por sí solas y es muy aburrido para los niños tener que darles una y otra vez explicaciones."',
		author: ['Antoine de Saint-Exupéry', '"El Principito"'],
	},
	{
		sentence:
			'"Fue el tiempo que pasaste con tu rosa lo que la hizo tan importante."',
		author: ['Antoine de Saint-Exupéry', '"El Principito"'],
	},
	{
		sentence:
			'"No era más que un zorro semejante a cien mil otros. Pero yo le hice mi amigo y ahora es único en el mundo."',
		author: ['Antoine de Saint-Exupéry', '"El Principito"'],
	},
	{
		sentence:
			'"Es más fácil engañar a la gente que convencerla de que han sido engañados."',
		author: ['Mark Twain'],
	},
	{
		sentence: '"Si no ha terminado bien, es que aún no ha terminado."',
		author: ['Fernando Sabino', '"El Ensayo como Género Literario"'],
	},
	{
		sentence: '"Eres lo que haces, no lo que dices que vas a hacer."',
		author: ['Carl Gustav Jung'],
	},
	{
		sentence:
			'"La gente va a hacer cualquier caso, no importa lo absurdo que esto sea, para evitar hacer frente a sus propias almas."',
		author: ['Carl Gustav Jung'],
	},
	{
		sentence:
			'"El mundo te preguntará quién eres, y si no lo sabes, responderá por ti."',
		author: ['Carl Gustav Jung'],
	},
	{
		sentence: '"...y conoceréis la verdad, y la verdad os hará libres."',
		author: ['Juan 8:32'],
	},
	{
		sentence:
			'"He aprendido a no intentar convencer a nadie. El trabajo de convencer es una falta de respeto, es un intento de someter al otro."',
		author: ['José Saramago'],
	},
	{
		sentence:
			'"Cuando hagas algo noble y hermoso, y nadie se de cuenta, no estés triste. El amanecer es un espectáculo hermoso y sin embargo la mayor parte de la audiencia duerme todavía."',
		author: ['John Lennon'],
	},
];
