export const COUNTRIES = [
	'Andorra',
	'Argentina',
	'Bahamas',
	'Barbados',
	'Belice',
	'Bolivia',
	'Brasil',
	'Canadá',
	'Chile',
	'Colombia',
	'Costa Rica',
	'Cuba',
	'Dominica',
	'Ecuador',
	'Estados Unidos',
	'El Salvador',
	'España',
	'Francia',
	'Granada',
	'Guatemala',
	'Guyana',
	'Haití',
	'Honduras',
	'Inglaterra',
	'Italia',
	'Jamaica',
	'México',
	'Nicaragua',
	'Panamá',
	'Paraguay',
	'Perú',
	'Portugal',
	'Puerto Rico',
	'República Dominicana',
	'San Cristóbal y Nieves',
	'San Vicente y las Granadinas',
	'Santa Lucía',
	'Surinam',
	'Trinidad y Tobago',
	'Uruguay',
	'Venezuela',
	'Otro...',
];
