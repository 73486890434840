import React from 'react';

const IconSearch = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			fill='none'
			stroke='black'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			className='w-6 h-6'
		>
			<circle cx='11' cy='11' r='8'></circle>
			<line x1='21' y1='21' x2='16.65' y2='16.65'></line>
		</svg>
	);
};

export default IconSearch;
