import React from 'react';

const IconCrystal1 = ({ size = '60px' }) => {
	return (
		<svg
			className='p-3'
			fill='#ffff'
			version='1.1'
			width={size}
			height={size}
			viewBox='0 0 256 256'
			enableBackground='new 0 0 256 256'
			filter='drop-shadow(0px 0px 8px rgb(250 250 0 / .7))'
		>
			<path
				d='M211.3,86.9l-40.5,15.7l7.5-47.8L120.6,8L62.9,54.7l13.3,84.4l-35.5-13.9l-27.8,42.1l40,80.6h133.9l29.6-59.8l26.7-52.7
      L211.3,86.9z M204,104.1l-0.3,21.9l-17.6-6.5L204,104.1z M81.1,238l-20.2-83l-14.8-17.2l31.9,13L91.8,238H81.1z M77,56.1l28.8-23.8
      l-10.5,25L77,56.1z M140.7,238l7.1-71.3l6.3-20.1L127,162.3V238h-11.3l-9.6-178.9l14.6-38.2l47.2,37.9l-13.8,87.8l21,23.2L153,238
      H140.7z M179.9,238h-8.4l42.9-107.9l-0.5-21l17.7,27L179.9,238z'
			/>
		</svg>
	);
};

export default IconCrystal1;
