export const ASTRAL_SURGERY_FAQ = [
	// El primer objeto vacío está para 'solucionar' el bug del primer
	// contenedor que no se expande lo suficiente...
	{
		question: '',
		answer: [''],
	},
	{
		question: '¿Para qué sirve la Cirugía Astral?',
		answer: [
			'La intención principal de la Cirugía Astral, es remover específicamente uno o varios nodos de energía que no permitan el correcto funcionamiento del cuerpo energético.',
			'Estos nodos de energía negativos, pueden ser causados por un dolor que lleva mucho tiempo, un trauma, un conflicto psíquico o emocional, una parasitación energética, un daño energético puesto intencionalmente, etc.',
			'Muchas veces, al existir un bloqueo o daño energético específico, el cuerpo Biológico se adapta en función de ello, pudiendo manifestarse lo que percibimos como enfermedad, lesión, dolor, malestar, etc.',
			'Puede afectar al cuerpo psíquico en forma de pensamientos conflictivos, auto-saboteamiento, distracción, procrastinación, etc.',
			'Puede afectar al cuerpo emocional en forma de tristesa, depresión, enojo, desmotivación, etc.',
			'De esta manera, al remover el bloqueo específico, la energía puede volver a fluir como debería, volviendo todos los cuerpos a un estado de claridad, equilibrio y bienestar.',
			'Siendo que si una dolencia física era causa de este bloqueo, al quitar el bloqueo, el cuerpo físico podrá comenzar su proceso de reparación',
		],
	},
	{
		question: '¿Funciona a distancia?',
		answer: [
			'¡Sí! Los aspectos energéticos a distancia son los mismos que de manera presencial, ya que se trabaja sobre los cuerpos sutiles.',
			'Recuerda que al trabajar con los cuerpos energéticos, los conceptos de Tiempo y Espacio funcionan de una manera mucho más sutil.',
			'Por eso es que es posible incidir sobre el cuerpo energético de otro, conectándose a través del Eter sin importar la distancia.',
		],
	},
	{
		question: '¿Tengo que saber o hacer algo especial?',
		answer: [
			'No es necesario saber ni hacer nada especial durante la sesión. Tanto de manera presencial o a distancia.',
			'Si se hace a distancia, simplemente se puede uno disponer de manera tranquila en un estado de relajación, meditación, y abrirse al efecto y a los efectos sanadores de la sesión.',
		],
	},
	{
		question: '¿Se puede encargar una sesión para un tercero?',
		answer: [
			'Sí, se puede, pero siempre es una condición imprescindible que quien vaya a recibir el trabajo de la sesión, dé su permiso conscientemente para que el Sanador pueda interactuar energéticamente con la energía del Consultante.',
			'No puede hacerse un tratamiento de sanación sin el consentimiento de quién lo va a recibir, ya que de hacerlo, simplemente el efecto no sería útil, o en ciertos casos podría generar un conflicto mayor, y resultar desfavorable tanto para el individuo como para el Sanador.',
		],
	},
	{
		question: '¿Se pueden hacer sesiones para bebes o niños?',
		answer: [
			'Sí. En caso de que el niño sea muy pequeño, es el padre, madre o responsable del niño quién brinda el consentimiento al Sanador para que pueda interactuar energéticamente con su cuerpo energético.',
			'Siempre en casos de terceros y similares, el Sanador hará una conexión en meditación con el individuo a tratar, para pedir el permiso directamente al Yo Superior, Espíritu, o Alma, correspondientemente.',
		],
	},
	{
		question:
			'¿Se puede hacer una sesión a alguien inconsciente (en estado de coma), alguien en estado Senil, con Alzheimer, o similar?',
		answer: [
			'Por norma general Sí, y en este caso, el que daría el consentimiento en el plano Físico sería el responsable moral del individuo a tratar.',
			'Siempre en casos de terceros y similares, el Sanador hará una conexión en meditación con el individuo a tratar, para pedir el permiso directamente al Yo Superior, Espíritu, o Alma, correspondientemente.',
			'En estos casos lo correspondiente es contactar con nosotros, y ver los pormenores del caso en concreto, y encontrar la mejor manera de aplicar estas técnicas.',
		],
	},
	{
		question: '¿Cuales son los efectos de una Cirugía Astral?',
		answer: [
			'Los efectos pueden ser variados, dependiendo de qué esté transitando el individuo a quién se le practica.',
			'Por lo general se pueden encontrar efectos como: Recobrar la vitalidad, mejor sueño, mejor humor, liberación o ligereza emocional, recobrar la voluntad, claridad mental, positividad, quitar dolor de cabeza, creatividad, reconectar con uno mismo, etc.',
			'En casos donde hay una dolencia física o enfermedad, al remover el bloqueo energético específico, y desbloquear el fluir de los cuerpos energéticos, el cuerpo Físico puede comenzar su proceso de reparación.',
			'Siendo que la mayoría de estos efectos, son causados por un desequilibrio energético, una Cirugía Astral sencillamente ayuda a deshacer o eliminar un bloqueo o daño energético, el resto son "efectos secundarios".',
		],
	},
	{
		question: '¿Duele una Cirugía Astral?',
		answer: [
			'¡No! El efecto de bienestar, en la mayoría de los casos es inmediato.',
			'En casos donde los conflictos de los cuerpos energéticos se desencadenaron en síntomas físicos, puede ocurrir que, al devolver la armonía al cuerpo energético, el cuerpo físico se disponga a sanar, o volver a su estado de funcionamiento normal.',
			'A veces, este proceso de "Reparación Biológica", puede estar acompañado de molestias, dependiendo el Programa Biológico que se esté trancitando.',
			'Por ejemplo, en algunos casos donde se lleva tiempo con el cuerpo energético en conflicto o disonancia, el cuerpo Biológico se Adapta para Sobrevivir. Al disolver el conflicto energético/emocional, el cuerpo Biológico se dispone a sanar, a veces acompañado por Fiebre, Vómitos, u otros síntomas de "Limpieza".',
		],
	},
	{
		question: '¿Cuántas sesiones necesito?',
		answer: [
			'Eso depende totalmente de vos.',
			'Con las Cirugías Astrales se ayuda a restablecer la armonía al cuerpo sutiles. Si esta desarmonía fue por una causa específica, probablemente con una sola encuentres tu centro nuevamente.',
			'Hay que tener en cuenta que si se lleva un estilo de vida donde uno interactúa con "Malas Energías" frecuentemente; Como en el trabajo, en contexto doméstico, conflictos sociales en general, o pensamientos nocivos, puede ser que, por más sesiones de cualquier tipo que uno haga, vuelva el cuerpo energético a desarmonizarse.',
		],
	},
	{
		question: '¿Cómo es una sesión presencial?',
		answer: [
			'Las sesiones presenciales, son en la ciudad de Mar Del Plata, Argentina.',
			'Se reserva el turno previamente, donde se acuerda día y horario.',
			'La sesión dura aproximadamente una hora.',
			'Primero se conversa con el consultante sobre cual es el motivo por el que quiere la sesión de Cirugía Astral, el Por qué, el Para qué, y las expectativas.',
			'Luego se recuesta en la camilla, con la compañía de una música relajante. Y desde un estado sensible y sensitivo se procede a conectar con la energía del consultante. Se realiza una limpieza de los cuerpos sutiles, y con la ayuda de un cuarzo cristal y energía del eter, se remueve lo que esté afectando a alguno de estos cuerpos. Ayudando desde ese plano a sanar emociones, o energía estancada. Reflejándose luego en el campo físico, en la apertura de caminos, en el estado de ánimo y en la forma renovada de encarar su propia vida.',
			'Después de atender los bloqueos específicos, se realiza la alineación y activación de los Chakras.',
			'Al finalizar el tratamiento energético, se conversa sobre la experiencia, qué sintió el consultante y cosas que se puedan haber percibido durante la sesión que podrían ser relevantes para el proceso por el que se esté transitando.',
		],
	},
	{
		question: '¿Cómo es una sesión a distancia?',
		answer: [
			'Se reserva el turno previamente, donde se acuerda día y horario.',
			'La sesión dura aproximadamente una hora.',
			'Primero se realiza una pequeña video-llamada, para conocernos, y contactar con la energía del consultante.',
			'Se conversa con el consultante sobre cuál es el motivo por el que quiere la sesión de Cirugía Astral, el Por qué, el Para qué, y las expectativas.',
			'Luego se comienza el tratamiento, para esto, se invita al consultante a estar en un momento y espacio en que pueda estar de manera tranquila y relajada, y si prefiere hacer una meditación y recostarse, sería lo ideal.',
			'Desde un estado sensible y sensitivo se procede a conectar con la energía del consultante, donde realiza una limpieza de los cuerpos sutiles, y con la ayuda de un cuarzo cristal y energía del eter, se remueve lo que esté afectando a alguno de estos cuerpos. Ayudando desde ese plano a sanar emociones, o energía estancada. Reflejándose luego en el campo físico, en la apertura de caminos, en el estado de ánimo y en la forma renovada de encarar su propia vida.',
			'Después de atender los bloqueos específicos, se realiza la alineación y activación de los Chakras.',
			'Al finalizar el tratamiento energético, se puede hacer otra pequeña video-llamada para conversar sobre la experiencia, qué sintió el consultante y cosas que se puedan haber percibido durante la sesión que podrían ser relevantes para el proceso por el que se esté transitando.',
			'A veces, como luego de la sesión se permanece en un agradable estado de relajación, si prefiere no realizar inmediatamente esa video-llamada, se le hace en forma de mensajes de audio una devolución al consultante de cosas que se puedan haber percibido durante la sesión, que puedan resultarle relevante para el proceso por el que esté transitando.',
		],
	},
];
