import img1 from '../../assets/images/astralSurgery/astralSurgery_img_1.jpg';
import img2 from '../../assets/images/astralSurgery/astralSurgery_img_2.jpg';
import img3 from '../../assets/images/astralSurgery/astralSurgery_img_3.jpg';
import img4 from '../../assets/images/astralSurgery/astralSurgery_img_4.jpg';
import img5 from '../../assets/images/astralSurgery/astralSurgery_img_5.jpg';
import img6 from '../../assets/images/astralSurgery/astralSurgery_img_6.jpg';
import img7 from '../../assets/images/astralSurgery/astralSurgery_img_7.jpg';

export const ASTRAL_SURGERY_IMAGES = [
	{ id: 7, img: img7 },
	{ id: 2, img: img2 },
	{ id: 1, img: img1 },
	{ id: 3, img: img3 },
	{ id: 4, img: img4 },
	{ id: 5, img: img5 },
	{ id: 6, img: img6 },
];
