import img1 from '../../assets/images/gemstoneTherapy/gemoterapia-foto-1.jpg';
import img2 from '../../assets/images/gemstoneTherapy/gemoterapia-foto-2.jpg';
import img3 from '../../assets/images/gemstoneTherapy/gemoterapia-foto-3.jpg';
import img4 from '../../assets/images/gemstoneTherapy/gemoterapia-foto-4.jpg';
import img5 from '../../assets/images/gemstoneTherapy/gemoterapia-foto-5.jpg';
import img6 from '../../assets/images/gemstoneTherapy/gemoterapia-foto-6.jpg';

export const GEMSTONES_IMAGES = [
	{ id: 1, img: img1 },
	{ id: 2, img: img2 },
	{ id: 3, img: img3 },
	{ id: 4, img: img4 },
	{ id: 5, img: img5 },
	{ id: 6, img: img6 },
];
