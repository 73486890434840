import React from 'react';

const FullBackGround = () => {
	return (
		<div
			className={`absolute top-0 left-0 w-screen h-screen
				bg-background-1 bg-cover bg-center
				saturate-[40%] brightness-[80%]`}
		></div>
	);
};

export default FullBackGround;
