import { PATHS } from '../../constants/paths';

export const CARD_DATA = [
	{
		title: 'Gemoterapia',
		description:
			'La Gemoterapia es una herramienta para Desbloquear y Armonizar el cuerpo energético. Los Chakras son centros energéticos de nuestro cuerpo, a traves de los cuales fluye nuestra energía vital. A veces, por dolor, estrés, tristesa, malos pensamientos, se pueden bloquear, desarmonizar, o distorcionar uno o varios Chakras, impidiendo que nuestra energía fluya correctamente. Provocando mal humor, cansancio, baja energía y con el tiempo síntomas más notables físicos, psíquicos y emocionales. Dependiendo la naturaleza de la causa y la experiencia del individuo, diferentes Chakras se pueden alterar de distinta manera en correlacción a la intencidad y tipo de conflicto. Con Gemoterapia, se trabaja directamente con la energía de los cristales naturales de la tierra, activándolos sobre el cuerpo energético del individuo a tratar. Al ser una técnica energética, se puede practicar de manera presencial así como también a distancia, viendo resultados en todos los casos.',
		image: 'bg-img-2',
		route: PATHS?.GEMSTONE_THERAPY?.route,
	},
	{
		title: 'Cirugía Astral',
		description:
			'La Cirugía Astral es una técnica de sanación energética que se puede considerar más precisa e intensa (como una cirugía), donde se usa como instrumento de intervención el poder y la precisión de un Cuarzo Cristalino. Se busca dónde está el bloqueo energético en concreto, esté asociado directamente al cuerpo físico o energético, luego se procede a través de un proceso de técnicas concretas, visualización y canalización espontánea de cada circunstancia, a remover ese elemento energético que pueda estar ocasionando un mal mayor. A veces se remueven conflictos emocionales, trabas mentales, parásitos etéricos, o diferentes tipos de fenómenos localizados que incurran en la generación de diferentes dolencias.',
		image: 'bg-img-3',
		route: PATHS?.ASTRAL_SURGERY?.route,
	},
	{
		title: 'Tarot Holístico',
		description:
			'Con Tarot Holístico, usamos el arte de la Cartomancia, como medio y herramienta para acercarnos a diferentes aspectos de nuestra experiencia. Nos permite una aproximación a nuestro subconsciente, a nuestro inconsciente, a los aspectos ocultos de nuestra experiencia mental, emocional y espiritual. Puede ayudarnos a encontrar el camino, a tomar una decisión importante, a conocernos a nosotros mismos, a entender mejor a otros, a prepararnos sensatamente para el por venir, a encontrar nuestro propósito de vida, y tantas otras utilidades como creativo sea nuestra postura ante esta práctica adivinatoria.',
		image: 'bg-img-6',
		route: PATHS?.TAROT?.route,
	},
];
