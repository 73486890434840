import React from 'react';
import './Loader.css';

const Loader = () => {
	return (
		<div className='flex justify-center items-center h-fit w-[100px] scale-50'>
			<div className='loader'></div>
		</div>
	);
};

export default Loader;
