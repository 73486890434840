const useStyles = () => {
	const styles = {
		1: 'text-[30px] isMobile:text-[1.5rem]',
		2: 'text-[20px]',
		3: 'text-[15px]',
		4: 'text-[18px] leading-5',
		5: `text-xl text-shadow-3 text-center isMobile:text-base`,
	};
	return styles;
};

export default useStyles;
