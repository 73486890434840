import React from 'react';

const IconCross = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 20 20'
			width='25'
			height='25'
			fill='black'
		>
			<path
				fill-rule='evenodd'
				d='M12.182 10l4.95-4.95a1 1 0 0 0-1.414-1.414L10.768 8.586 5.818 3.636a1 1 0 0 0-1.414 1.414L9.354 10l-4.95 4.95a1 1 0 1 0 1.414 1.414L10.768 11.414l4.95 4.95a1 1 0 0 0 1.414-1.414L12.182 10z'
				clip-rule='evenodd'
			/>
		</svg>
	);
};

export default IconCross;
