export const TAROT_FAQ = [
	// El primer objeto vacío está para 'solucionar' el bug del primer
	// contenedor que no se expande lo suficiente...
	{
		question: '',
		answer: [''],
	},
	{
		question: '¿Para qué sirve el Tarot Holístico?',
		answer: [
			'El Tarot nos sirve para explorarnos a nosotros mismos y nuestra experiencia vital.',
			'Nos sirve para aclarar nuestra mente, sanar, encontrar respuestas, incrementar nuestra certidumbre, tomar decisiones, comprendernos a nosotros mismos, a los demás, y las situaciones que experimentamos en nuestra vida.',
			'Con el concepto de "Holístico", nos referimos a que no solo es una práctica para hacer preguntas, obtener respuestas y terminar ahí. Sino que también es un servicio de acompañamiento a comprender esas respuestas, y hacerlas útiles en nuestra vida, en forma de consejo, recomendación, claridad, orientación, comprensión, etc.',
		],
	},
	{
		question: '¿Funciona a distancia?',
		answer: [
			'¡Sí! Los aspectos energéticos a distancia son los mismos que de manera presencial.',
			'Cuando se trabaja con energías se cuenta con esta ventaja.',
			'Con el Tarot, la información a la que accedemos está en el Eter, por lo que gracias a la tecnología, las consultas a distancia no son un problema.',
		],
	},
	{
		question: '¿Tengo que saber o hacer algo especial?',
		answer: [
			'No es necesario saber ni hacer nada especial durante la sesión. Tanto de manera presencial o a distancia.',
			'Solo has de estar dispuesto a encontrarte con una verdad, que quizá pueda ser inesperada o diferente a tus concepciones actuales.',
		],
	},
	{
		question: '¿Puedo preguntar cualquier cosa?',
		answer: [
			'En el caso de las preguntas, tu plantearás sobre qué tema quieres explorar o encontrar claridad.',
			'Sin embargo el Tarotista te ayudará a formular la pregunta, de la manera más sensata posible, para que el Tarot responda de una manera que sea útil para el bien mayor del consultante.',
			'También es importante saber que las preguntas que se harán, son determinadas por el Tarotista, quién puede considerar que ciertas preguntas o temas, no sean convenientes preguntar en ese momento, o de esa manera.',
		],
	},
	{
		question: '¿Qué tan preciso es el Tarot?',
		answer: [
			'La precisión del Tarot está condicionada directamente por la convicción del Consultante, y su intención de conocer la verdad.',
			'Así como también del estado presente del propio Tarotista',
			'Usualmente los consultantes que tienen mayor convicción y certeza de que el Tarot funciona, obtienen respuestas e informaciones más claras y precisas.',
			'Por otro lado, los consultantes, inseguros, con dudas, o que no creen en estos asuntos, al no canalizar su energía Psíquica correctamente, el Tarot puede dar respuestas muy ambiguas, haciendo la interpretación del Tarotista, demasiado genérica, o directamente imprecisa.',
		],
	},
	{
		question: '¿Puedo tomar sesiones regularmente?',
		answer: [
			'Sí, por supuesto.',
			'El Tarot Holístico tiene un complemento de Acompañamiento Terapéutico.',
			'Sin embargo, hay que tener en cuenta que eso no implica hacer reiteradamente una misma pregunta, esperando respuestas diferentes, hasta encontrar una que sea satisfactoria.',
			'En ese caso, y como parte de la sesión, se conversará para encontrar cual es el motivo profundo de esa sensación de reiterar en la misma pregunta o asunto.',
		],
	},
	{
		question: '¿Es solo hacer preguntas y tener respuestas?',
		answer: [
			'¡No!, el Tarot Holístico es un acompañamiento integral.',
			'También conversaremos sobre tí, sobre lo que quieras compartir, y buscaremos la mejor manera de acercarnos a ello y solucionarlo.',
			'Compartiremos reflexión, filosofía, catarsis, y ahondaremos hasta las raíces más profundas.',
			'El Tarot, será una herramienta, nuestro compañero, que estará ahí presente, para asistirnos cuando sea necesario.',
		],
	},
	{
		question: '¿Qué pasa si no creo en el Tarot?',
		answer: [
			'Si no crees en el Tarot, lo sensato será invertir tu tiempo y energía en donde consideres más oportuno.',
			'Podrías tener la curiosidad de tomar una consulta de Tarot, con la intención de "poner a prueba" las capacidades adivinatorias.',
			'El problema de esto, es que si la energía psíquica del consultante, no es honesta, no está alineada, ni correctamente canalizada, desvirtuará las respuestas que el Tarot nos de, llegando a hacer incluso que el Tarot conteste simplemente incoherencias en forma de "protesta".',
		],
	},
	{
		question: '¿Se puede encargar una sesión para un tercero?',
		answer: [
			'¡Sí, se puede!',
			'Puede ser un lindo regalo para compartir con alguien',
			'Pero siempre con la consideración de que no es una práctica lúdica o de entretenimiento, sino que es una experiencia para alcanzar niveles profundos sobre unos mismo, y sobre los eventos de la vida.',
			'Es menester que el consultante, aunque sea por un regalo, esté mínimamente consciente con estos aspectos del Tarot Holístico.',
		],
	},
	{
		question: '¿Se pueden hacer sesiones para bebes o niños?',
		answer: [
			'Estas sesiones la pueden hacer los padres o tutores a cargo, para encontrar orientación en el cuidado, crianza y acompañamiento de los niños.',
			'Pero los consultantes han de ser al menos adolescentes, y comprender los aspectos esenciales del Tarot.',
			'Ya que la practica ha de ser consciente por parte tanto del Tarotista como del Consultante.',
		],
	},
	{
		question: '¿Cómo es una sesión presencial?',
		answer: [
			'Las sesiones presenciales, son en la ciudad de Mar Del Plata, Argentina.',
			'Se reserva el turno previamente, donde se acuerda día y horario.',
			'La primer sesión dura entre 1:30 y 2 horas.',
			'El resto de las sesiones pueden ser de 1 hora hasta 2 horas, discreción del consultante.',
			'En la primer sesión, se hace una lectura general, donde nos conocemos entre los tres; El Consultante, El Tarotista y El Tarot. Ya que para cada quién las cartas se comunican de manera distinta. Y luego de esto, podemos comenzar a atender temas y preguntas concretas.',
			'Al comenzar la sesión se hará unos pequeños ejercicios de limpieza energética, concentración, y conexión con el momento y el Tarot, guiados por el Tarotista.',
			'Luego se desarrollará toda la sesión, conversando, consultando al Tarot, o usando el Tarot para comprender o filosofar sobre diversos Arquetipos o temas.',
			'Antes de finalizar, se hará otro pequeño ejercicio de cierre, para concluir la apertura energética, dispersar las energías densas que se puedan haber generado, y realizar o no, una pequeña meditación si fuere pertinente.',
		],
	},
	{
		question: '¿Cómo es una sesión a distancia?',
		answer: [
			'Las sesiones a distancia, son en lo práctico, iguales a las presenciales, por lo que te invito a leer la pregunta anterior.',
			'Como particular, se invita al consultante, a buscar un espacio privado e íntimo, en que pueda estar en solitario y sin interrupciones durante la duración de la sesión.',
			'Si le es posible, se le invita a evitar las notificaciones o llamadas inesperadas.',
			'También a preparar elementos que puedan servir a la concentración del consultante en su propio espacio como; sahumerios, velas, cuenco con agua, cristales, música relajante, etc. Y tener preparado un cuaderno y un lápiz.',
			'Durante el comienzo y el final de la sesión, se conversará con el Tarotista a través de videollamada, por la plataforma a acordar previamente por mensajes.',
			'Y durante el desarrollo de la sesión, la cámara enfocará al paño donde se verán las cartas, para visualización del consultante.',
		],
	},
	{
		question: '¿Puedo hacer preguntas Adivinatorias sobre el Futuro?',
		answer: [
			'Cuando se plantean preguntas de carácter Adivinatorio, el Tarotista conversará con el consultante para comprender el Por Qué y el Para Qué del deseo de conocer esa información',
			'En la mayoría de los casos, no es útil conocer el futuro, puede generar ansiedad, depresión o paranoia, conduciendo al consultante, en lugar de a un estado más pleno y seguro, hacia una caída de la estabilidad psíquica y la energía vital.',
			'La pregunta Adivinatoria sobre el Futuro, se hará a discreción del Tarotista, solo si considera que pueda tener un uso sensato y positivo para el desarrollo del consultante.',
		],
	},
	{
		question: '¿Puedo hacer preguntas sobre otras personas?',
		answer: [
			'Sí, se puede hacer preguntas sobre otras personas, siempre y cuando la pregunta tenga un sentido contributivo para el desarrollo individual del consultante.',
			'Si el Tarotista considera que la persona no tiene un vínculo lo suficientemente relevante para el consultante, o la pregunta tiene el carácter de chisme, se conducirá entonces la sesión a comprender trasfondo de la necesidad de preguntar sobre ello.',
		],
	},
	{
		question: '¿Puedo preguntar sobre Fechas o Tiempo?',
		answer: [
			'Preguntar al Tarot sobre el Tiempo, siempre es un gran reto, y generalmente no se alcanza la precisión que al consultante le gustaría.',
			'Hay que tener en cuenta que el tiempo no es más que el continuo cambio de estado de las cosas, que nosotros percibimos mentalmente como una sucesión lineal de eventos.',
			'El hecho de que el Consultante cambie su conducta luego de la sesión, hará que la dinámica de las energías cambie, por lo que haya cosas que ocurran antes, u ocurran después, o incluso no ocurran nunca.',
			'Además el Tarotista determinará la relevancia y utilidad para el consultante de saber sobre un período de tiempo, y determinará si es útil para el consultante, realizar o no dicha pregunta.',
		],
	},
	{
		question: '¿Puedo preguntar sobre diagnósticos médicos?',
		answer: [
			'Si bien el Tarot puede utilizarse para preguntar cualquier cosa, no es sensato utilizarlo para diagnósticos médicos, o cualquier otra información a la que se puede acceder de manera más precisa por otros medios.',
			'Si usted quiere saber si tiene una enfermedad por ejemplo en el estómago, lo más sensato es ir al médico, y obtener un diagnóstico clínico preciso.',
			'Sin embargo con el Tarot, se puede ahondar y explorar el Por Qué de esa dolencia, su causa, su motivo, su razón, y comprender el conflicto biológico de fondo.',
		],
	},
	{
		question: '¿Puedo preguntar sobre la Muerte?',
		answer: [
			'Normalmente el conocimiento sobre una muerte, no es conducente para el consultante a sanar algún aspecto de su vida.',
			'Junto con el Tarotista, ahondarán en por qué se quiere acceder a esa información, y se determinará si es propicio realizar tal pregunta.',
		],
	},
	{
		question: '¿Puedo realizar varias veces la misma pregunta?',
		answer: [
			'No. Durante una misma sesión, no es correcto hacer la misma pregunta varias veces.',
			'Y si se quiere hacer la misma pregunta a lo largo de varias sesiones, el Tarotista conversará con el consultante, para ver qué aspectos relevantes y significativos cambiaron, para que sea válido volver a preguntar sobre un tema.',
			'Ten en cuenta que preguntar varias veces lo mismo a un Tarot, es igual que preguntarle varias veces lo mismo a una persona.',
			'¿Cómo te sentirías vos, si luego de responder una pregunta, te la volvieran a repetir?',
		],
	},
];
